<template>
  <div class="pageBox">
    <div class="content-block">
      <el-form :model="form" :rules="rules" ref="form" label-width="auto" class="infoForm">
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item label="政府/事业单位名称" prop="companyName">
              <el-input
                aria-label="政府/事业单位名称"
                aria-autocomplete="none"
                clearable
                placeholder="请输入政府/事业单位名称"
                type="text"
                v-model.trim="form.companyName"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item label="统一社会信用代码" prop="creditCode">
              <el-input
                aria-label="统一社会信用代码"
                aria-autocomplete="none"
                clearable
                placeholder="请输入统一社会信用代码"
                type="text"
                v-model.trim="form.creditCode"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item label="上传政府/事业单位法人证书" prop="legalPersonCertificate">
              <el-upload
                class="uploaderFile"
                accept=".png, .jpg"
                :action="configBaseURL+'/api/upload'"
                :data="{type:'account'}"
                :show-file-list="false"
                :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'licinseNumberPhoto')}"
                :before-upload="beforeUpload"
                disabled
              >
                <img
                  v-if="form.legalPersonCertificate"
                  class="file disabled"
                  :src="form.legalPersonCertificate"
                />
                <i v-else class="el-icon-plus icon"></i>
              </el-upload>
              <div class="themeColor">请上传政府/事业单位法人证书，图片格式为png、jpg，图标大小不能超过2M。</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item label="法人姓名" prop="corporationName">
              <el-input
                aria-label="法人姓名"
                aria-autocomplete="none"
                clearable
                placeholder="请输入法人姓名"
                type="text"
                v-model.trim="form.corporationName"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item label="法人身份证" prop="corporationCardName">
              <el-input
                aria-label="法人身份证"
                aria-autocomplete="none"
                clearable
                placeholder="请输入法人身份证"
                type="text"
                v-model.trim="form.corporationCardName"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <div class="flex">
              <el-form-item class="margin0" label="上传法人身份证" prop="legalPersonPositivePhoto">
                <el-upload
                  class="uploaderFile"
                  accept=".png, .jpg"
                  :action="configBaseURL+'/api/upload'"
                  :data="{type:'account'}"
                  :show-file-list="false"
                  :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'licinseNumberPhoto')}"
                  :before-upload="beforeUpload"
                  disabled
                >
                  <img
                    v-if="form.legalPersonPositivePhoto"
                    class="file disabled"
                    :src="form.legalPersonPositivePhoto"
                  />
                  <i v-else class="el-icon-plus icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item
                class="margin0"
                label
                prop="legalPersonNegativePhoto"
                label-width="10px"
              >
                <el-upload
                  class="uploaderFile"
                  accept=".png, .jpg"
                  :action="configBaseURL+'/api/upload'"
                  :data="{type:'account'}"
                  :show-file-list="false"
                  :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'licinseNumberPhoto')}"
                  :before-upload="beforeUpload"
                  disabled
                >
                  <img
                    v-if="form.legalPersonNegativePhoto"
                    class="file disabled"
                    :src="form.legalPersonNegativePhoto"
                  />
                  <i v-else class="el-icon-plus icon"></i>
                </el-upload>
              </el-form-item>
            </div>
            <el-form-item class="margin0">
              <div class="themeColor">请上传身份证正反面图片，图片格式为png、jpg，大小不能超过2M。</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item label="是否有法人手持身份证" prop="legalPersonHoldPhotoType">
              <el-radio-group
                aria-label="是否有法人手持身份证"
                aria-autocomplete="none"
                type="text"
                v-model="form.legalPersonHoldPhotoType"
                disabled
              >
                <div class="margin-t10">
                  <el-radio label="1">有法人手持身份证照片</el-radio>
                </div>
                <div class="margin-t10">
                  <el-radio label="2">无法人手持身份证照片，需上传被授权人的身份证正反面及手持身份证照片、授权书</el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <template v-if="form.legalPersonHoldPhotoType==1">
          <el-row>
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item class="margin0" label="上传法人手持身份证照片" prop="legalPersonHoldPhoto">
                <el-upload
                  class="uploaderFile"
                  accept=".png, .jpg"
                  :action="configBaseURL+'/api/upload'"
                  :data="{type:'account'}"
                  :show-file-list="false"
                  :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'licinseNumberPhoto')}"
                  :before-upload="beforeUpload"
                  disabled
                >
                  <img
                    v-if="form.legalPersonHoldPhoto"
                    class="file disabled"
                    :src="form.legalPersonHoldPhoto"
                  />
                  <i v-else class="el-icon-plus icon"></i>
                </el-upload>
                <div class="themeColor">请上传法人手持身份证反面图片，图片格式为png、jpg，大小不能超过2M。</div>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <template v-else>
          <el-row>
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <div class="flex">
                <el-form-item label="上传被授权人身份证" prop="authorizePositivePhoto">
                  <el-upload
                    class="uploaderFile"
                    accept=".png, .jpg"
                    :action="configBaseURL+'/api/upload'"
                    :data="{type:'account'}"
                    :show-file-list="false"
                    :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'licinseNumberPhoto')}"
                    :before-upload="beforeUpload"
                    disabled
                  >
                    <img
                      v-if="form.authorizePositivePhoto"
                      class="file disabled"
                      :src="form.authorizePositivePhoto"
                    />
                    <i v-else class="el-icon-plus icon"></i>
                  </el-upload>
                </el-form-item>
                <el-form-item label prop="authorizeNegativePhoto" label-width="10px">
                  <el-upload
                    class="uploaderFile"
                    accept=".png, .jpg"
                    :action="configBaseURL+'/api/upload'"
                    :data="{type:'account'}"
                    :show-file-list="false"
                    :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'licinseNumberPhoto')}"
                    :before-upload="beforeUpload"
                    disabled
                  >
                    <img
                      v-if="form.authorizeNegativePhoto"
                      class="file disabled"
                      :src="form.authorizeNegativePhoto"
                    />
                    <i v-else class="el-icon-plus icon"></i>
                  </el-upload>
                </el-form-item>
              </div>
              <div class="flex">
                <el-form-item prop="authorizeHoldPhoto" label-width="195px">
                  <el-upload
                    class="uploaderFile"
                    accept=".png, .jpg"
                    :action="configBaseURL+'/api/upload'"
                    :data="{type:'account'}"
                    :show-file-list="false"
                    :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'licinseNumberPhoto')}"
                    :before-upload="beforeUpload"
                    disabled
                  >
                    <img
                      v-if="form.authorizeHoldPhoto"
                      class="file disabled"
                      :src="form.authorizeHoldPhoto"
                    />
                    <i v-else class="el-icon-plus icon"></i>
                  </el-upload>
                </el-form-item>
                <el-form-item prop="authorizeCertificate" label-width="10px">
                  <el-upload
                    class="uploaderFile"
                    accept=".png, .jpg"
                    :action="configBaseURL+'/api/upload'"
                    :data="{type:'account'}"
                    :show-file-list="false"
                    :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'licinseNumberPhoto')}"
                    :before-upload="beforeUpload"
                    disabled
                  >
                    <img
                      v-if="form.authorizeCertificate"
                      class="file disabled"
                      :src="form.authorizeCertificate"
                    />
                    <i v-else class="el-icon-plus icon"></i>
                  </el-upload>
                </el-form-item>
              </div>
              <el-form-item>
                <div class="themeColor">请上传被授权人身份证正反面、手持身份证反面及授权书照片，图片格式为png、jpg，大小不能超过2M</div>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item label="联系人姓名" prop="contactName">
              <el-input
                aria-label="联系人姓名"
                aria-autocomplete="none"
                clearable
                placeholder="请输入联系人姓名"
                type="text"
                v-model.trim="form.contactName"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item label="联系人邮箱" prop="contactEmail">
              <el-input
                aria-label="联系人邮箱"
                aria-autocomplete="none"
                clearable
                placeholder="请输入联系人邮箱"
                type="text"
                v-model.trim="form.contactEmail"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item label="联系电话" prop="contactPhone">
              <el-input
                aria-label="联系电话"
                aria-autocomplete="none"
                clearable
                placeholder="请输入联系电话"
                type="text"
                v-model.trim="form.contactPhone"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item label="微信" prop="weixin">
              <el-input
                aria-label="微信"
                aria-autocomplete="none"
                clearable
                placeholder="请输入微信"
                type="text"
                v-model.trim="form.weixin"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item label="QQ" prop="qq">
              <el-input
                aria-label="QQ"
                aria-autocomplete="none"
                clearable
                placeholder="请输入QQ"
                type="text"
                v-model.trim="form.qq"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item>
              <div class="submitBotton" @click="submitForm('form')">提交</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { checkPhone, checkWeixin } from "@/utils/RegExp.js";
import utils from "@/utils/util.js";
import { singleAccountSever } from "@/common/api-config";

export default {
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {

  },
  data() {
    return {
      infoStatus: true,
      rules: {
        /*type=3：政府/事业单位*/
        companyName: [
          {
            required: true,
            message: "请输入政府/事业单位名称",
            trigger: "blur",
          },
        ],
        creditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        legalPersonCertificate: [
          {
            required: true,
            message: "请上传传政府/事业单位法人证书",
            trigger: "blur",
          },
        ],
        corporationName: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
        ],
        corporationCardName: [
          { required: true, message: "请填写法人身份证", trigger: "blur" },
        ],
        legalPersonPositivePhoto: [
          { required: true, message: "请上传身份证正面照片", trigger: "blur" },
        ],
        legalPersonNegativePhoto: [
          { required: true, message: "请上传身份证反面照片", trigger: "blur" },
        ],
        legalPersonHoldPhotoType: [
          {
            required: true,
            message: "请勾选是否有法人手持身份证",
            trigger: "blur",
          },
        ],
        legalPersonHoldPhoto: [
          {
            required: true,
            message: "请上传法人手持身份证照片",
            trigger: "blur",
          },
        ],
        authorizePositivePhoto: [
          {
            required: true,
            message: "请上传被授权人正面照片",
            trigger: "blur",
          },
        ],
        authorizeNegativePhoto: [
          {
            required: true,
            message: "请上传被授权人反面照片",
            trigger: "blur",
          },
        ],
        authorizeHoldPhotoFile: [
          {
            required: true,
            message: "请上传被授权人手持身份证照片",
            trigger: "blur",
          },
        ],
        authorizeCertificate: [
          { required: true, message: "请上传法人授权书", trigger: "blur" },
        ],
        /*共用*/
        contactName: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        contactEmail: [
          { required: true, message: "请输入联系人邮箱", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        weixin: [
          { required: true, message: "请输入联系人微信", trigger: "blur" },
          { required: true, validator: checkWeixin, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //上传前校验
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf('.'));
      if(fileType == '.jpg' || fileType == '.png'){
       
      }else{
        this.$message.error('请上传jpg或png的图片！');
        return false
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    //上传成功
    handleUpload(res, file, filelist, key) {
      if (res.code == 0) {
        this.form[key] = res.data.link;
      } else {
        this.$message.error("上传失败");
      }
    },
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          singleAccountSever.improveData(this.form).then((res) => {
            if (res.code == 0) {
              this.$store
                .dispatch("accountStore/updateAccountInfo", {})
                .then((res) => {
                  this.$msgBox
                    .alertMessageBox({
                      content: "您的账号资料修改成功",
                    })
                    .then(async (data) => {
                      this.$router.go(-1);
                    })
                    .catch((val) => {});
                });
            } else {
              this.$message.error("提交失败");
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.pageBox {
  .content-block {
    width: 992px;
    .title {
      position: relative;
      margin: 50px 0;
      font-size: 24px;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -19px;
        width: 57px;
        height: 6px;
        background: #ded094;
        border-radius: 3px;
        transform: translateX(-50%);
      }
    }
    .infoForm {
      background: #fff;
      .submitBotton {
        width: 400px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: rgba(40, 38, 44, 1);
        box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.07);
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}
</style>